<template>
  <div>
    <template v-if="professionals && !hasAcceptedDisclaimer">
      <div class="container post">
        <div class="wrapper">
          <div class="grid">
            <div class="col-12">
              <div class="section">
                <h2 class="section-hero">Er du helsepersonell?</h2>
                <p class="confirmations">
                  <a href="javascript: void(0)" @click="acceptDisclaimer()"
                    >Ja</a
                  >
                  <router-link to="/">Nei</router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div v-if="category">
        <div class="container full featured">
          <div class="wrapper" :class="{ 'no-image': !category.image }">
            <div class="text">
              <h1>{{ category.title }}</h1>
              <Blocks
                v-if="category.description"
                :blocks="category.description"
              />
            </div>
            <div class="image" v-if="category.image">
              <img :src="getImageUrl(category, 1440, 'image')" />
            </div>
          </div>
        </div>
        <div v-if="category && category.ads" class="container full featured">
          <div class="wrapper">
            <div
              class="featured-image"
              :style="{
                backgroundImage: `url('${getImageUrl(category.ads, '1440')}')`,
              }"
            ></div>
          </div>
        </div>
        <div class="container cloud">
          <div class="wrapper">
            <div
              class="grid sections no-padding"
              v-if="category.sub_category && category.sub_category.length > 0"
            ></div>
            <div class="grid">
              <template
                v-if="category.sub_category && category.sub_category.length > 0"
              >
                <div
                  class="col-4 col-md-6 col-sm-12"
                  v-for="cat in category.sub_category"
                  :key="cat._key"
                >
                  <div class="badge">
                    <div class="top">
                      <router-link
                        :to="{
                          name: 'category',
                          params: { slug: getSlug(cat) },
                        }"
                        class="image"
                        :style="{
                          backgroundImage: `url(${getImageUrl(
                            cat,
                            1080,
                            'image'
                          )})`,
                        }"
                      />
                    </div>
                    <div class="bottom">
                      <h3>
                        <router-link
                          :to="{
                            name: 'category',
                            params: { slug: getSlug(cat) },
                          }"
                          >{{ cat.title }}</router-link
                        >
                      </h3>

                      <p v-if="cat.description">
                        <router-link
                          :to="{
                            name: 'category',
                            params: { slug: getSlug(cat) },
                          }"
                          v-html="getDescription(cat.description)"
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="col-12" v-if="category.posts.length == 0">
                  <p>Ingen artikler har blitt postet her enda.</p>
                </div>
                <div
                  v-else
                  v-for="post in category.posts"
                  :key="post._key"
                  class="col-4 col-md-6 col-sm-12"
                >
                  <div class="badge">
                    <div class="top">
                      <router-link
                        :to="{
                          name: post._type === 'post' ? 'post' : 'page',
                          params: { slug: getSlug(post) },
                        }"
                        class="image"
                        :style="{
                          backgroundImage: `url(${getImageUrl(post)})`,
                        }"
                      />
                    </div>
                    <div class="bottom">
                      <div
                        class="flex flex-group flex-wrap"
                        v-if="
                          (post.categories && post.categories.length > 0) ||
                            (post.sponsor && post.sponsor.info_overview)
                        "
                      >
                        <router-link
                          v-for="cat in post.categories"
                          :to="{
                            name: 'category',
                            params: { slug: getSlug(cat) },
                          }"
                          :key="cat._key"
                          href=""
                          class="pill"
                          >{{ cat.title }}</router-link
                        >
                        <span
                          v-if="post.sponsor && post.sponsor.info_overview"
                          class="pill"
                        >
                          {{ post.sponsor.info_overview }}
                        </span>
                      </div>
                      <h2>
                        <router-link
                          :to="{
                            name: post._type === 'post' ? 'post' : 'page',
                            params: { slug: getSlug(post) },
                          }"
                          >{{ post.title }}</router-link
                        >
                      </h2>
                      <p v-if="post.intro">
                        <router-link
                          :to="{
                            name: post._type === 'post' ? 'post' : 'page',
                            params: { slug: getSlug(post) },
                          }"
                          >{{ getIntro(post) }}</router-link
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import sanity from "../../sanity";
import imageUrlBuilder from "@sanity/image-url";
import text from "../../helpers/text";
import linebreak from "../../helpers/linebreak";
import substring from "../../helpers/substring";
import slug from "../../helpers/slug";
import disclaimer from "../../helpers/disclaimer";
import date from "../../helpers/date";
import Blocks from "../../components/Blocks";

const imageBuilder = imageUrlBuilder(sanity);

export default {
  components: {
    Blocks,
  },
  data() {
    return {
      loading: false,
      loaded: false,
      category: null,
      hasAcceptedDisclaimer: false,
      professionals: false,
    };
  },

  metaInfo() {
    return {
      title: this.loading ? "" : `${this.category.title} : `,
    };
  },

  created() {
    this.fetchCategory(this.$route.params.slug);
    this.hasAcceptedDisclaimer = disclaimer.get();
  },

  mounted() {},

  watch: {
    "$route.params.slug": function(slug) {
      this.fetchCategory(slug);
    },
  },

  methods: {
    fetchCategory(slug) {
      // @todo: Remove temp redirect due to categories being unpublished
      if (slug === "behandling-barn-unge" || slug === "behandling-voksne") {
        location.href = "/";
      }

      this.loading = true;
      sanity
        .fetch(
          `
                    *[_type == "category" && !(_id in path('drafts.**')) && (slug.current == $slug || _id == $slug)][0]{
                        ...,
                        "posts": *[ (_type == "post" || _type == "page") && references(^._id) && !(_id in path('drafts.**')) && (publishedAt == null || publishedAt < $date)]{
                            ...,
                            "top": [@.top == true][0],
                            categories[]{
                                ...,
                                "title": @->title,
                                "slug": @->slug
                            },
                            sponsor {
                                ...,
                                "info_overview": @->info_overview,
                            },
                        } | order(top desc, publishedAt desc),
                        ads {
                            ...,
                            "title": @->title,
                            "mainImage": @->mainImage
                        },
                        sub_category[] {
                            ...,
                            "id": @->id,
                            "slug": @->slug,
                            "title": @->title,
                            "description": {
                              ...,
                            },
                            "image": @->image
                        },
                        description[]{
                          ...,
                          _type == 'button' => {
                              ...,
                              "title": @->title,
                              "text": @->text,
                              "href": @->href,
                              "blank": @->blank,
                              "small": @->small,
                          },
                        }
                    }
                `,
          {
            slug: slug,
            date: date.currentFullUTCDate(),
          }
        )
        .then(
          (response) => {
            this.category = response;
            this.professionals = this.category.professionals;
          },
          (error) => {
            console.warn(error);
          }
        )
        .then(() => {
          this.loading = false;
          this.loaded = true;
        });
    },

    getIntro(post) {
      return substring(text(post.intro), 92);
    },

    getImageUrl(source, height = 1080, img = "mainImage") {
      if (!source[img]) {
        return "";
      }

      return imageBuilder
        .image(source[img])
        .format("webp")
        .height(height)
        .url();
    },

    getSlug(element) {
      return slug(element);
    },

    getDescription(text) {
      return linebreak(text);
    },

    acceptDisclaimer() {
      this.hasAcceptedDisclaimer = true;
      disclaimer.set(true);
    },
  },
};
</script>
