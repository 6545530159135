var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.professionals && !_vm.hasAcceptedDisclaimer)?[_c('div',{staticClass:"container post"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"grid"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"section"},[_c('h2',{staticClass:"section-hero"},[_vm._v("Er du helsepersonell?")]),_c('p',{staticClass:"confirmations"},[_c('a',{attrs:{"href":"javascript: void(0)"},on:{"click":function($event){return _vm.acceptDisclaimer()}}},[_vm._v("Ja")]),_c('router-link',{attrs:{"to":"/"}},[_vm._v("Nei")])],1)])])])])])]:[(_vm.category)?_c('div',[_c('div',{staticClass:"container full featured"},[_c('div',{staticClass:"wrapper",class:{ 'no-image': !_vm.category.image }},[_c('div',{staticClass:"text"},[_c('h1',[_vm._v(_vm._s(_vm.category.title))]),(_vm.category.description)?_c('Blocks',{attrs:{"blocks":_vm.category.description}}):_vm._e()],1),(_vm.category.image)?_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":_vm.getImageUrl(_vm.category, 1440, 'image')}})]):_vm._e()])]),(_vm.category && _vm.category.ads)?_c('div',{staticClass:"container full featured"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"featured-image",style:({
              backgroundImage: ("url('" + (_vm.getImageUrl(_vm.category.ads, '1440')) + "')"),
            })})])]):_vm._e(),_c('div',{staticClass:"container cloud"},[_c('div',{staticClass:"wrapper"},[(_vm.category.sub_category && _vm.category.sub_category.length > 0)?_c('div',{staticClass:"grid sections no-padding"}):_vm._e(),_c('div',{staticClass:"grid"},[(_vm.category.sub_category && _vm.category.sub_category.length > 0)?_vm._l((_vm.category.sub_category),function(cat){return _c('div',{key:cat._key,staticClass:"col-4 col-md-6 col-sm-12"},[_c('div',{staticClass:"badge"},[_c('div',{staticClass:"top"},[_c('router-link',{staticClass:"image",style:({
                        backgroundImage: ("url(" + (_vm.getImageUrl(
                          cat,
                          1080,
                          'image'
                        )) + ")"),
                      }),attrs:{"to":{
                        name: 'category',
                        params: { slug: _vm.getSlug(cat) },
                      }}})],1),_c('div',{staticClass:"bottom"},[_c('h3',[_c('router-link',{attrs:{"to":{
                          name: 'category',
                          params: { slug: _vm.getSlug(cat) },
                        }}},[_vm._v(_vm._s(cat.title))])],1),(cat.description)?_c('p',[_c('router-link',{attrs:{"to":{
                          name: 'category',
                          params: { slug: _vm.getSlug(cat) },
                        }},domProps:{"innerHTML":_vm._s(_vm.getDescription(cat.description))}})],1):_vm._e()])])])}):[(_vm.category.posts.length == 0)?_c('div',{staticClass:"col-12"},[_c('p',[_vm._v("Ingen artikler har blitt postet her enda.")])]):_vm._l((_vm.category.posts),function(post){return _c('div',{key:post._key,staticClass:"col-4 col-md-6 col-sm-12"},[_c('div',{staticClass:"badge"},[_c('div',{staticClass:"top"},[_c('router-link',{staticClass:"image",style:({
                        backgroundImage: ("url(" + (_vm.getImageUrl(post)) + ")"),
                      }),attrs:{"to":{
                        name: post._type === 'post' ? 'post' : 'page',
                        params: { slug: _vm.getSlug(post) },
                      }}})],1),_c('div',{staticClass:"bottom"},[(
                        (post.categories && post.categories.length > 0) ||
                          (post.sponsor && post.sponsor.info_overview)
                      )?_c('div',{staticClass:"flex flex-group flex-wrap"},[_vm._l((post.categories),function(cat){return _c('router-link',{key:cat._key,staticClass:"pill",attrs:{"to":{
                          name: 'category',
                          params: { slug: _vm.getSlug(cat) },
                        },"href":""}},[_vm._v(_vm._s(cat.title))])}),(post.sponsor && post.sponsor.info_overview)?_c('span',{staticClass:"pill"},[_vm._v(" "+_vm._s(post.sponsor.info_overview)+" ")]):_vm._e()],2):_vm._e(),_c('h2',[_c('router-link',{attrs:{"to":{
                          name: post._type === 'post' ? 'post' : 'page',
                          params: { slug: _vm.getSlug(post) },
                        }}},[_vm._v(_vm._s(post.title))])],1),(post.intro)?_c('p',[_c('router-link',{attrs:{"to":{
                          name: post._type === 'post' ? 'post' : 'page',
                          params: { slug: _vm.getSlug(post) },
                        }}},[_vm._v(_vm._s(_vm.getIntro(post)))])],1):_vm._e()])])])})]],2)])])]):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }